import React from 'react';

import { useLocation } from 'react-router-dom'

import Seo from '../Seo'

const Terms = ({ conditionTitel, conditionData }) => {
    const location = useLocation();

    return (
        <>

            <Seo
                title={conditionTitel + "| gravitywalk.in | Vegetables & Fruits , Grocery , Chicken & Fish "}
                descreption={conditionTitel + " | gravitywalk.in | gravitywalk.in is an online vegetable, fruit, cake ,chicken, and grocery delivery website and app in Gorakhpur , Which deliver you home at very low prices. Vegetables & Fruits , Grocery , Chicken & Fish "}
                image={null}

            />


            <React.Fragment class="">
                {location.pathname == '/faq' ?

                    (
                        <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                            <h4 class="mb-4 profile-title"> {conditionTitel} </h4>
                            <div id="basics">

                                <div id="basicsAccordion">





                                    {conditionData.length ? (

                                        <>
                                            {conditionData.map((item, i) => {
                                                return (
                                                    <div class="box border rounded mb-1 bg-white">
                                                        <div id={"ABC" + i}>
                                                            <h5 class="mb-0">
                                                                <button class="shadow-none btn btn-block d-flex align-items-center justify-content-between card-btn p-3 collapsed" data-toggle="collapse" data-target={"#col" + i} aria-expanded="false" aria-controls="basicsCollapseOne">
                                                                    {item.question}
                                                                    <i class="icofont-simple-down"></i>
                                                                </button>
                                                            </h5>
                                                        </div>
                                                        <div id={"col" + i} class="collapse " aria-labelledby={"ABC" + i} data-parent="#basicsAccordion" >
                                                            <div class="card-body border-top p-3 text-muted">
                                                                {item.answer}
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </>


                                    ) : null}


                                </div>

                            </div>
                        </div>
                    ) :

                    (
                        <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                            <h4 class="mb-4 profile-title">Shipping Policy</h4>
                            <div id="terms_conditions">
                            <section>
                            <p>At Gravity Walk, we offer several shipping options to ensure that you receive your medications in a timely and secure manner.</p>
        
        <h3>Standard Shipping</h3>
        <p>Our standard shipping option typically takes 5-7 business days for delivery. This option is available for all orders and is free for orders over $50.</p>

        <h3>Express Shipping</h3>
        <p>If you need your medications faster, we offer express shipping which takes 2-3 business days for delivery. Additional charges apply for this option.</p>

        <h3>International Shipping</h3>
        <p>We also provide international shipping to select countries. Delivery times for international orders may vary depending on the destination. Please note that customers are responsible for any customs or import duties that may apply.</p>
    </section>

    <section>
        <h2>Shipping Charges</h2>
        <p>Shipping charges are calculated based on the shipping method you choose and the total order amount. The exact shipping cost will be displayed during the checkout process before you confirm your order.</p>
    </section>

    <section>
        <h2>Tracking Your Order</h2>
        <p>Once your order is shipped, we will provide you with a tracking number via email. You can use this tracking number to monitor the status and estimated delivery date of your package.</p>
    </section>

    <section>
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our shipping policy or the status of your order, please don't hesitate to contact our customer support team at <a href="mailto:contact@gravitywalk.com">contact@gravitywalk.com</a>.</p>
    </section>
                            </div>
                        </div>
                    )

                }
            </React.Fragment>

        </>
    )

}

export default Terms;