import React from 'react';
import Category from './Category';
import OshanContainer from '../comman/OshanContainer';
import Promo from './Promo';
import TodayPicks from './TodayPicks';
import Banner from './Banner';
import Banner2 from './Banner2';
import SubCategorySliding from './SubCategorySliding';


import Seo from '../Seo'

const HomeComponent = (props) => {

    return (
        <>

            <Seo
                title={"Gravity Walk "}
                descreption={"Welcome to Gravity Walk, your trusted online pharmacy where your health and satisfaction are our top priorities."}
                image={null}

            />




            <OshanContainer >



                {/* <SubCategorySliding /> */}

                <Banner />

                {/* <Category /> */}



                <Promo />

                <Banner2 />

                <TodayPicks />

            </OshanContainer>
        </>
    )

}

export default HomeComponent;