import React from 'react';

import { useLocation } from 'react-router-dom'

import Seo from '../Seo'

const PrivacyPolicy = ({ conditionTitel, conditionData }) => {
    const location = useLocation();

    return (
        <>

            <Seo
                title={conditionTitel}
                descreption={conditionTitel}
                image={null}

            />


            <React.Fragment class="">
              
                        <div class="col-lg-12 p-4 bg-white rounded shadow-sm">
                            <h4 class="mb-4 profile-title">Privacy Policy</h4>
                            <div id="terms_conditions">
                          
         <section>
            <h2>1. Introduction</h2>
            <p>Welcome to Gravity Walk ("us", "we", or "our"). We are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our website and services.</p>
        </section>
        
        <section>
            <h2>2. Information We Collect</h2>
            <p>We collect various types of information, including but not limited to:</p>
            <ul>
                <li>Personal Information (such as name, contact information, and billing details) provided by you during account registration and order processing.</li>
                <li>Health-related information you provide when seeking pharmaceutical products.</li>
                <li>Usage data, including your interactions with our website, products, and services.</li>
                <li>Cookies and similar tracking technologies to enhance your experience.</li>
            </ul>
        </section>
        
        <section>
            <h2>3. How We Use Your Information</h2>
            <p>We use your personal information for the following purposes:</p>
            <ul>
                <li>Processing and fulfilling your orders.</li>
                <li>Communicating with you regarding your orders and providing customer support.</li>
                <li>Improving our website, products, and services.</li>
                <li>Sending promotional offers, updates, and newsletters (you can opt-out).</li>
                <li>Complying with legal requirements.</li>
            </ul>
        </section>
        
        <section>
            <h2>4. Data Security</h2>
            <p>We implement industry-standard security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is entirely secure. While we strive to protect your information, we cannot guarantee absolute security.</p>
        </section>
        
        <section>
            <h2>5. Third-Party Disclosure</h2>
            <p>We may share your information with third parties to facilitate services (e.g., shipping partners) or as required by law. We do not sell your personal information to third parties for their marketing purposes.</p>
        </section>
        
        <section>
            <h2>6. Your Choices</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Review and update your personal information.</li>
                <li>Opt-out of receiving promotional emails.</li>
                <li>Request the deletion of your account (subject to legal obligations).</li>
            </ul>
        </section>
        
        <section>
            <h2>7. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us:</p>
            <address>
                Email: info@gravitywalk.com<br/>
               
            </address>
        </section>

                                </div>
                        </div>
                    

                
            </React.Fragment>

        </>
    )

}

export default PrivacyPolicy;